import React from 'react';
import tw from 'twin.macro';
import { graphql } from 'gatsby';
import Sandbox from '../atoms/sandbox';
import Breadcrumb from '../atoms/breadcrumb';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const Partner = ({ data }) => {
  const image = getImage(data.logo.localFile);

  return (
    <div tw="py-6 border-b border-status-amber xl:py-10">
      {image && (
        <GatsbyImage image={image} tw="mb-4" alt={data.logo.alternativeText} />
      )}
      <h3 tw="font-bold mb-4">{data.title}</h3>
      <Sandbox html={data.text} tw="text-sm xl:text-base" />
    </div>
  );
};

const Partners = ({ data }) => {
  return (
    <div
      css={[
        tw`relative pt-6 px-6 pb-12`,
        tw`sm:(px-20 pb-20)`,
        tw`lg:(px-28 pb-28)`,
        tw`xl:(px-48)`,
      ]}
    >
      <div tw="max-w-screen-lg mx-auto text-center md:text-left">
        {data.breadcrumb && <Breadcrumb text={data.breadcrumb} />}
        {data.partners &&
          data.partners.map((partner) => (
            <Partner key={`partner_${partner.id}`} data={partner} />
          ))}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment Partners on STRAPI_ComponentContainersPartners {
    id
    breadcrumb
    partners {
      id
      logo {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(width: 280)
          }
        }
        alternativeText
      }
      title
      text
      website
    }
  }
`;
export default Partners;
